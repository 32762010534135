import TomSelect from 'tom-select/dist/js/tom-select.base.js';

// import caret_position from './plugins/caret_position/plugin';
// import dropdown_input from './plugins/dropdown_input/plugin';
// import no_backspace_delete from './plugins/no_backspace_delete/plugin';
import remove_button from 'tom-select/dist/js/plugins/remove_button.js';
import clear_button from 'tom-select/dist/js/plugins/clear_button.js';
// import restore_on_backspace from './plugins/restore_on_backspace/plugin';

// TomSelect.define('caret_position', caret_position);
// TomSelect.define('dropdown_input', dropdown_input);
// TomSelect.define('no_backspace_delete', no_backspace_delete);
TomSelect.define('remove_button', remove_button);
TomSelect.define('clear_button', clear_button);
// TomSelect.define('restore_on_backspace', restore_on_backspace);

export default TomSelect;
